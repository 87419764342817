import {partition} from '../helpers';

export type ElementId = string;
export type FlowElement<T = unknown> = Node<T> | Edge<T>;
export type Elements<T = unknown> = Array<FlowElement<T>>;

export type ReactFlowJsonObject<NodeData = unknown, EdgeData = unknown> = {
  nodes: Node<NodeData>[];
  edges: Edge<EdgeData>[];
  viewport: Viewport;
};

/** Legacy React Flow export shape */
export type FlowExportObject<T = unknown> = {
  elements: (Node<T> | Edge<T>)[];
  position: [number, number];
  zoom: number;
};

export const isLegacyFlowExport = <T = unknown>(
  obj: unknown
): obj is FlowExportObject<T> => {
  if (!obj) {
    return false;
  }
  if (typeof obj === 'object') {
    if ('elements' in obj && 'position' in obj && 'zoom' in obj) {
      return true;
    }
  }
  return false;
};

export const upgradeLegacyFlowExport = <T>(
  obj: ReactFlowJsonObject<T, T> | FlowExportObject<T>
): ReactFlowJsonObject<T, T> => {
  if (typeof obj === 'object' && 'edges' in obj) {
    return obj;
  }
  const [edges, nodes] = partition(obj.elements, (el) => 'source' in el);
  return {
    // Casting is safe here, as the saved values can only be nodes or edges.
    nodes: nodes as Node<T>[],
    edges: edges as Edge<T>[],
    viewport: {
      x: obj.position[0],
      y: obj.position[1],
      zoom: obj.zoom,
    },
  };
};

export const downgradeLegacyFlowExport = <T>(
  obj?: Partial<ReactFlowJsonObject<T, T>> | FlowExportObject<T>
): FlowExportObject<T> => {
  if (typeof obj === 'object' && 'elements' in obj) {
    return obj;
  }
  const {nodes = [], edges = [], viewport} = obj ?? {};
  return {
    elements: [...nodes, ...edges],
    position: [viewport?.x ?? 0, viewport?.y ?? 0],
    zoom: viewport?.zoom ?? 1,
  };
};

export type Viewport = {
  x: number;
  y: number;
  zoom: number;
};

export interface XYPosition {
  x: number;
  y: number;
}

export interface Node<T = unknown> {
  id: ElementId;
  type?: string;
  data: T;
  position: XYPosition;
  animated?: boolean;
}

export interface Edge<T = unknown> {
  id: ElementId;
  type?: string;
  data?: T;
  label?: string;
  source: ElementId;
  sourceHandle?: ElementId | null;
  target: ElementId;
  targetHandle?: ElementId | null;
}
