import {Type, type Static} from '@sinclair/typebox';
import {type Variants} from 'framer-motion';
import {useMemo, type SetStateAction} from 'react';
import {type Instruction} from '../hooks/context/types';
import {isJSONObject, type JSONValue} from '../types';
import {isInstruction} from './instruction-helpers';
import {PublishesTo, SubscribesTo} from './typebox-helpers';

export interface MotionOptions {
  /** The animation variants that will be applied to the Framer Motion component. See: https://www.framer.com/docs/animation/#variants */
  variants: Variants;
  /** The name of the variant that will be applied when the page loads. See: https://www.framer.com/docs/component/###initial */
  initial?: string;
}

/** helper copy that describes the animation state to the end user */
export const animationDescription = 'The name of an animation state';

/** helper copy that describes the animation state to the end user */
export const onAnimationDescription = 'The animation state has been completed';

/** Schema definition for shared animation instructions */
export const AnimationInstructions = Type.Union([
  SubscribesTo({
    topic: `Animate:to-state`,
    description: animationDescription,
    meta: {stateName: Type.String({title: 'State Name'})},
  }),
  PublishesTo({
    topic: `Animate:on-complete`,
    description: onAnimationDescription,
    meta: {stateName: Type.Optional(Type.String({title: 'State Name'}))},
  }),
]);

/**
 * the shape of the animation states in a component's settings
 */
export const animationStates = Type.Optional(
  Type.Array(
    Type.Object({
      /** The name of the animation state/variant from DLB */
      name: Type.String({title: 'Name'}),
      /**
       * The stringified object of Framer Motion animation values. Modelled as a
       * variant, see: https://www.framer.com/docs/animation/#variants
       */
      tween: Type.String({
        title: 'Animation Variant',
        description:
          'The animation variant options, see Framer Motion documentation: framer.com/docs/animation/#variants',
      }),
      /**
       * If true, this variant will be set as the `initial` variant and will be
       * applied to the component on page load
       */
      isDefault: Type.Optional(
        Type.Boolean({
          title: 'Initial state',
          description: 'Set the module to this animation state on page load',
        })
      ),
    }),
    {
      title: 'Animation States',
    }
  )
);

export type AnimationState = Static<typeof animationStates>[number];

/**
 * properties that describe how to render the UI for the animationStates fields
 */
export const animationUi = {
  animationStates: {
    'ui:options': {
      orderable: true,
    },
    items: {
      tween: {
        'ui:widget': 'modalTextareaWidget',
        'ui:options': {
          buttonTitle: 'Edit Tween Options',
          editor: 'CodeMirror',
        },
      },
    },
  },
};

/** the shared error message for the applyTween function */
const runTweenErr = 'The `applyTween` function failed';

/**
 * Attempts to build the Framer Motion animation options `variants` and `initial`
 * allowing for the inital animation state to be set and the `animate` Framer motion option
 * to be set, based on the `variants`, by instructions from the flow editor
 * @param animationStates - a list of animation states to search through
 */
export function useMotionOptions(
  animationStates: AnimationState[] | undefined
): MotionOptions | undefined {
  return useMemo(() => {
    if (animationStates && animationStates.length > 0) {
      const variants: Variants = {};
      let initialVariant: string | undefined = undefined;

      // build the Framer Motion Variants based off of the animation states
      for (const variant of animationStates) {
        try {
          const tween: JSONValue = JSON.parse(variant.tween);
          if (isJSONObject(tween)) {
            variants[variant.name] = tween;
          }
        } catch (error) {
          console.warn(`
            An invalid JSON format was provided as a tween value. Here are the details:
            animation state name: ${variant.name}
            JSON parse error: ${error}
          `);
        }

        if (variant.isDefault) {
          // set the inital variant
          initialVariant = variant.name;
        }
      }

      return {
        variants: variants,
        initial: initialVariant,
      };
    } else {
      return undefined;
    }
  }, [animationStates]);
}

/**
 * Attempts assign the current animation varient if it exists, this will trigger the animation in Framer motion
 * @param instruction - the animation state to be triggered
 * @param animationStates - a list of animation states to search through
 * @param setActiveVariant - the callback function to pass the animation tween to
 */
export const applyTween = (
  instruction: Instruction,
  animationStates: AnimationState[] | undefined,
  setActiveVariant: (tween: SetStateAction<string | undefined>) => void
): void => {
  // get the name of the animation state
  const stateName =
    isInstruction(instruction) &&
    'stateName' in instruction.meta &&
    (instruction.meta.stateName as string);

  // if the animation stateName doesn't exist, don't attempt to animate
  if (!stateName) {
    return console.debug(
      `${runTweenErr}: a stateName is required in order to run an animation`
    );
  }

  // get the details of the animation state using the state name passed in the instruction
  const animationState = animationStates?.find(
    ({name}) => name?.toLowerCase() === stateName.toLowerCase()
  );

  const isValidNonEmptyJSON = (str: string): boolean => {
    try {
      const parsed: JSONValue = JSON.parse(str);
      return isJSONObject(parsed) && Object.keys(parsed).length > 0;
    } catch (e) {
      return false;
    }
  };

  if (!animationState) {
    // no animation state, don't attempt to animate
    return console.debug(
      `${runTweenErr}: the stateName, ${stateName}, from the flow editor does not match known animation states/variants within the module.`,
      animationStates
        ? ` The available states are: ${animationStates.map(
            (s) => ` ${s.name}`
          )} `
        : 'No animation states available',
      instruction.meta.about
    );
  } else if (isValidNonEmptyJSON(animationState.tween)) {
    // valid non empty tween object, set the active variant to animate
    setActiveVariant(stateName);
  } else {
    // invalid json or empty object
    return console.debug(
      `${runTweenErr}: Either the animation object is empty or it is not valid JSON`
    );
  }
};
