import type {AnalyticsPayloadRequest} from '@backstage/api-types/analytics';
import {useSubscription} from 'observable-hooks';
import {useCallback, useEffect, useMemo, useRef} from 'react';
import {bufferTime, Subject} from 'rxjs';
import {getFlatKeys} from '../helpers';
import {type BroadcastFunction} from './context/transformations/node.types';
import {getMask} from './context/transformations/analytics-masks';
import {Instruction} from './context/types';

/** Maximum duration to buffer instructions before transmitting */
const BUFFER_MS = 10 * 1000; // multiply "seconds" to get milliseconds
/** Maximum number of instructions to buffer before transmitting */
const BUFFER_ITEMS = 25;

/**
 * Create a tracking function which collects instructions to be transmitted to
 * the analytics capture endpoint. Delivery latency of the instruction is
 * tracked if the `createdAt` timestamp is included in the instruction passed to
 * `trackInstruction`; the `createdAt` timestamp should _only_ be included if
 * the instruction was not part of the initial load.
 */
export function useTrackInstruction(
  props: ShowInstructionAnalyticsProviderProps
): BroadcastFunction {
  const {
    analyticsEndpoint,
    analyticsToken,
    domainName = 'unknown-domain',
    showId,
  } = props;
  // Collects instructions which have been broadcast for analytics
  const analyticsRef = useRef(new Subject<AnalyticsInstruction>());
  const analyticsBuffer = useRef<AnalyticsInstruction[]>([]);
  const trackInstruction: BroadcastFunction = useCallback(
    (instruction, source = 'internal') => {
      const masked = maskInstruction(instruction);
      const analyticsInstruction: AnalyticsInstruction = {
        createdTimestamp: instruction.createdAt,
        localTimestamp: new Date(),
        meta: masked.meta,
        type: masked.type,
        source,
      };
      analyticsRef.current.next(analyticsInstruction);
    },
    []
  );
  const handleAnalytics = useCallback(
    (instructions: AnalyticsInstruction[]) => {
      if (
        typeof analyticsEndpoint !== 'undefined' &&
        typeof analyticsToken !== 'undefined'
      ) {
        // making a copy then resetting the buffer, but always include at least
        // a heartbeat message
        const items = instructions.concat([
          {
            localTimestamp: new Date(),
            meta: {},
            type: '__heartbeat',
            source: 'internal',
          },
        ]);
        analyticsBuffer.current = [];
        transmitAnalytics(analyticsEndpoint, {
          showId,
          domainName,
          instructions: items,
          token: analyticsToken,
        });
      }
    },
    [analyticsEndpoint, analyticsToken, domainName, showId]
  );
  // Push each record into `analyticsBuffer` for use when page visibility
  // changes or component unmounts
  useSubscription(analyticsRef.current, (record) => {
    analyticsBuffer.current.push(record);
  });
  // Buffer the instructions so they are "emitted" every `BUFFER_MS` or every
  // `BUFFER_ITEMS` instructions
  const bufferedAnalytics$ = useMemo(
    () => analyticsRef.current.pipe(bufferTime(BUFFER_MS, null, BUFFER_ITEMS)),
    []
  );
  // When the buffer (duration or item count) is exceeded transmit analytics
  useSubscription(bufferedAnalytics$, (records) => {
    handleAnalytics(records);
  });
  // Transmit analytics when document visibility changes or when the component
  // unmounts (or `handleAnalytics` changes)
  useEffect(() => {
    if (typeof document === 'undefined') {
      return;
    }
    const handleVisibilityChange = (): void => {
      analyticsBuffer.current.push({
        localTimestamp: new Date(),
        meta: {visibilityState: document.visibilityState},
        type: '__document:visibilityChange',
        source: 'internal',
      });
      if (document.visibilityState === 'hidden') {
        handleAnalytics(analyticsBuffer.current);
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      // Transmit analytics (if any) when component unmounts but don't always
      // call `handleAnalytics` to avoid unnecessary heartbeats
      if (analyticsBuffer.current.length > 0) {
        handleAnalytics(analyticsBuffer.current);
      }
    };
  }, [handleAnalytics]);
  return trackInstruction;
}

interface ShowInstructionAnalyticsProviderProps {
  /** URL to which instructions will be transmitted as analytics */
  analyticsEndpoint?: string;
  /** Token used to identify a specific guest anonymously */
  analyticsToken?: string;
  /** Domain on which the `showId` was viewed */
  domainName?: string;
  /** Id of the show whose instructions are being handled. */
  showId: string;
}

/**
 * Apply the `AnalyticsInstructionMask` function from `getMask` to the given
 * `instruction`.
 * @throws if `instruction.type` and the `type` of the result of applying
 * `getMask` do not match
 * @throws if the result of applying the `getMask` is anything other than a
 * subset of the keys from the initial `instruction`
 */
function maskInstruction(instruction: Instruction): Instruction {
  const masked = getMask(instruction.type)(instruction);
  const originalKeys = getFlatKeys(instruction.meta);
  const maskedKeys = getFlatKeys(masked.meta);
  if (masked.type !== instruction.type) {
    throw new Error(
      [
        'Mask function returned a different instruction type.',
        `Expected: ${instruction.type}`,
        `Received: ${masked.type}`,
      ].join('\n')
    );
  } else if (!maskedKeys.every((key) => originalKeys.includes(key))) {
    throw new Error(
      [
        'Mask function returned a different set of keys.',
        `Expected (subset): ${originalKeys}`,
        `Received: ${maskedKeys}`,
      ].join('\n')
    );
  } else {
    return masked;
  }
}

type AnalyticsInstruction = AnalyticsPayloadRequest['instructions'][number];

/**
 * Sends analytics to the given `endpoint`.
 */
function transmitAnalytics(
  endpoint: string,
  payload: Omit<AnalyticsPayloadRequest, 'batchTimestamp' | 'userAgent'>
): boolean {
  if (typeof navigator === 'undefined') {
    return false;
  } else {
    const data: AnalyticsPayloadRequest = Object.assign(
      {batchTimestamp: new Date()},
      payload
    );
    // Create a blob so `sendBeacon` will set content-type header from blob type
    const body = new Blob([JSON.stringify(data)], {type: 'application/json'});
    return navigator.sendBeacon(endpoint, body);
  }
}
