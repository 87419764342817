import {
  Kind,
  Type,
  type Static,
  type TNever,
  type TObject,
  type TSchema,
  type TUnion,
} from '@sinclair/typebox';
import type {SetOptional} from 'type-fest';
import type {AnalyticsInstructionMask, JSONObject} from '../types';
import type {TypedComponentAdmin} from '../types/component-admin';
import {
  AnimationInstructions,
  animationStates,
  animationUi,
} from './animation-helpers';
import {styleAttr, styleAttrUi} from './style-helpers';
import type {DeriveInstructionType, InstructionSchema} from './typebox-helpers';

/** Extract the type of module properties from `TypedComponentAdmin` */
export type SchemaTypeHelper<CA> = CA extends TypedComponentAdmin<
  infer SettingsSchema,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  infer InstructionsSchema
>
  ? Static<SettingsSchema>
  : never;

/** Extract the type of module instructions from `TypedComponentAdmin` */
export type SchemaInstructionsHelper<CA> = CA extends TypedComponentAdmin<
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  infer SettingsSchema,
  infer InstructionsSchema
>
  ? DeriveInstructionType<InstructionsSchema>
  : never;

/**
 * Type Alias to manipulate module settings, adding `Key` with `Setting` to the
 * existing `Schema`.
 */
type AddSettingsProperty<
  Schema,
  Key extends string,
  Setting extends TSchema
> = Schema extends TObject<infer Properties>
  ? TObject<Properties & Record<Key, Setting>>
  : TObject<Record<Key, Setting>>;

/**
 * Type Alias to maniuplate instructions adding `ToAdd` to the existing
 * `Instructions` schema.
 */
type CombineInstructions<
  Instructions,
  ToAdd extends TUnion
> = Instructions extends TUnion<infer Existing>
  ? TUnion<[...Existing, ...ToAdd['anyOf']]>
  : ToAdd;

/**
 * Type alias representing the result of the `#build` method on the
 * `ComponentAdminBuilder` indicating instructions may be empty if they have a
 * type of `TNever`.
 */
type BuildResult<
  Schema extends TObject,
  Instructions extends TUnion | TNever,
  DefaultFieldData extends Static<Schema> = Static<Schema>,
  PrivateSchema extends TObject = TObject
> = Instructions extends TUnion
  ? TypedComponentAdmin<Schema, Instructions, DefaultFieldData, PrivateSchema>
  : Instructions extends TNever
  ? SetOptional<
      TypedComponentAdmin<
        Schema,
        TUnion<InstructionSchema[]>,
        DefaultFieldData,
        PrivateSchema
      >,
      'instructions'
    >
  : never;

class ComponentAdminBuilder<
  Schema extends TObject,
  Instructions extends TUnion | TNever,
  DefaultFieldData extends Static<Schema> = Static<Schema>,
  PrivateSchema extends TObject = TObject
> {
  #analyticsMask?: AnalyticsInstructionMask<Instructions>;
  #details: TypedComponentAdmin<
    Schema,
    Instructions,
    DefaultFieldData,
    PrivateSchema
  >;
  #defaultFieldData: DefaultFieldData;
  #instructions: Instructions;
  #privateSchema?: PrivateSchema;
  #schema: Schema;
  #uiSchema?: JSONObject;

  constructor(
    initial: TypedComponentAdmin<
      Schema,
      Instructions,
      DefaultFieldData,
      PrivateSchema
    >
  ) {
    this.#analyticsMask = initial.analyticsInstructionMask;
    this.#details = initial;
    this.#defaultFieldData = initial.defaultFieldData;
    this.#instructions = initial.instructions;
    this.#privateSchema = initial.privateSchema;
    this.#schema = initial.schema;
    this.#uiSchema = initial.uiSchema;
  }

  /**
   * Create the strongly typed component definition with any manipulations from
   * builder methods applied.
   */
  build(): BuildResult<Schema, Instructions, DefaultFieldData, PrivateSchema> {
    // Unable to work out the types necessary for the return value here,
    // presumably because `BuildResult` is a conditional type, and so the type
    // assertion is necessary.
    return {
      analyticsInstructionMask: this.#analyticsMask,
      category: this.#details.category,
      defaultFieldData: this.#defaultFieldData,
      description: this.#details.description,
      id: this.#details.id,
      instructions:
        this.#instructions[Kind] === 'Union' ? this.#instructions : undefined,
      name: this.#details.name,
      positionRestrictions: this.#details.positionRestrictions,
      privateSchema: this.#privateSchema,
      reactName: this.#details.reactName,
      schema: this.#schema,
      slotConfiguration: this.#details.slotConfiguration,
      slug: this.#details.slug,
      uiSchema: this.#uiSchema,
      version: this.#details.version,
    } as BuildResult<Schema, Instructions, DefaultFieldData, PrivateSchema>;
  }

  /**
   * Applies the given `mask` to the eventual `ComponentAdmin` definition.
   *
   * **WARNING** If called multiple times only the _latest_ mask will be
   * included in the definition.
   */
  withAnalyticsInstructionMask(
    mask: AnalyticsInstructionMask<Instructions>
  ): this {
    this.#analyticsMask = mask;
    return this;
  }

  /**
   * Adds properties and instructions necessary for animation functionality to
   * the `ComponentAdmin` definition.
   */
  withAnimationStates(): ComponentAdminBuilder<
    AddSettingsProperty<Schema, 'animationStates', typeof animationStates>,
    CombineInstructions<Instructions, typeof AnimationInstructions>,
    DefaultFieldData,
    PrivateSchema
  > {
    const instance = new ComponentAdminBuilder<
      AddSettingsProperty<Schema, 'animationStates', typeof animationStates>,
      CombineInstructions<Instructions, typeof AnimationInstructions>,
      DefaultFieldData,
      PrivateSchema
    >({
      ...this.#details,
      analyticsInstructionMask: this.#analyticsMask,
      defaultFieldData: this.#defaultFieldData,
      // @ts-expect-error shape of instructions can't be spread into union in a
      // type safe way
      instructions:
        this.#instructions[Kind] === 'Union'
          ? Type.Union([
              ...this.#instructions.anyOf,
              ...AnimationInstructions.anyOf,
            ])
          : AnimationInstructions,
      // @ts-expect-error there's a type mismatch due to conditional types not
      // being assignable
      // *NOTE*: `Type.Intersect` does not preserve additional properties passed
      // to the schema, like `dependencies`, which breaks some module schemas.
      schema: Object.assign({}, this.#schema, {
        properties: {...this.#schema.properties, animationStates},
      }),
      uiSchema: {
        ...this.#uiSchema,
        ...animationUi,
      },
    });
    return instance;
  }

  /**
   * Adds properties necessary for custom styling functionality to the
   * `ComponentAdmin` definition.
   */
  withStyles(): ComponentAdminBuilder<
    AddSettingsProperty<Schema, 'styleAttr', typeof styleAttr>,
    Instructions,
    DefaultFieldData,
    PrivateSchema
  > {
    const instance = new ComponentAdminBuilder<
      AddSettingsProperty<Schema, 'styleAttr', typeof styleAttr>,
      Instructions,
      DefaultFieldData,
      PrivateSchema
    >({
      ...this.#details,
      analyticsInstructionMask: this.#analyticsMask,
      defaultFieldData: this.#defaultFieldData,
      // @ts-expect-error there's a type mismatch due to conditional types not
      // being assignable
      // *NOTE*: `Type.Intersect` does not preserve additional properties passed
      // to the schema, like `dependencies`, which breaks some module schemas.
      schema: Object.assign({}, this.#schema, {
        properties: {...this.#schema.properties, styleAttr},
      }),
      uiSchema: {
        ...this.#uiSchema,
        ...styleAttrUi,
      },
    });
    return instance;
  }
}

/**
 * Create a builder for a `ComponentAdmin` instance with inferred instruction
 * types. The builder enables composing a `ComponentAdmin` instance
 * semi-fluently.
 */
export function createComponentAdmin<
  Schema extends TObject,
  Instructions extends TUnion | TNever,
  DefaultFieldData extends Static<Schema> = Static<Schema>,
  PrivateSchema extends TObject = TObject
>(
  props: TypedComponentAdmin<
    Schema,
    Instructions,
    DefaultFieldData,
    PrivateSchema
  >
): ComponentAdminBuilder<
  Schema,
  Instructions,
  DefaultFieldData,
  PrivateSchema
> {
  return new ComponentAdminBuilder(props);
}
